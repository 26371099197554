// 阿拉伯语
export default {
  // 登录页
  loginPage:{
    mobileNumber:'رقم الهاتف',
    password:'كلمة المرور',
    forgotPassword:'ننسى كلمة المرور',
    goToRegister:'التسجيل',
    rememberPassword:'تذكر كلمة المرور',
    login:'تسجيل الدخول',
    noAccountYet:' لا يوجد حساب؟',
    mobileNumberPlaceholder:'الرجاء إدخال رقم هاتفك المحمول',
    mobileNumberMessage:'الرجاء إدخال رقم هاتفك المحمول',
    passwordPlaceholder:'الرجاء إدخال كلمة المرور',
    passwordMessage:'الرجاء إدخال كلمة المرور',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  // inedx页面
  indexPage:{
    indexList:'فشل في الحصول على بيانات الصفحة الرئيسية',
    amazonLetMiaogouOpenYourWayToWealth:'AppLovinالسماح للثانية بفتح طريق الثروة الخاص بك',
    myAssets:'ممتلكاتي($)',
    recharge:'لاعادة الشحن',
    withdrawal:'التسهيلات',
    yesterdaysIncome:'أرباح الأمس($)',
    cumulativeIncome:'الأرباح المتراكمة($)',
    todaysIncome:'أرباح اليوم($)',
    freezeFunds:'تجميد الأموال($)',
    regularFinancialManagement:'المال بانتظام',
    quickRecharge:'شحن سريع',
    quickWithdrawal:'نقدية سريعة',
    inviteFriends:'دعوة الأصدقاء',
    taskHall:'قاعة المهمات',
    unlocked:'غير مغلق',
	service:'خدمة العملاء',
	leaderboard:'عرض'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'ثانية ثانية',
    this:'وهذه البيانات متاحة بصورة رسمية من الشركاء المتعاونين',
    Remaining:'الأصول المتاحة المتبقية($)',
    allOrders:'كل واحد',
    toBeProcessed:'تنتظر',
    completed:'اكتمل',
    freezing:'إنه مجمد',
    totalAmountOfTheOrder:'المبلغ الإجمالي للأوامر',
    commission:'عمولة',
    Estimated:'نتوقع عودة الخادمة',
    submitOrder:'تقديم الطلبات',
    secondpurchasetime:'ثانية ثانية:',
    secondPurchaseNumber:'ثانية ثانية:',
    NotSubmitted:'مؤجل',
    submitNow:'قدم على الفور'
  },
  // order页面
  orderPage:{
    grabAnOrder:'لا تتوقف عن السرقة',
    hiShop:'مرحبا شراؤها',
    winTheFutureWithYou:'يربح المستقبل معك',
    orderText:'تأسست applovin Marketplace في عام 1994&nbsp;، وتعمل مع أكثر من مليون شركة تجارة إلكترونية ، وتعالج 150.000 إلى 30 مليون طلب يوميًا. نظرًا لأن إدارة الأموال موكلة إلى شركة إدارة الأصول ، فيمكنها تجنب طلب استرداد المستخدم ورحلة رأس مال شركة التجارة الإلكترونية ، ويتم استخدام عميل السحابة الذكي وعنوان IP الخاص بالمستخدم وفقًا للقواعد المحددة. ستمنع مسابقة الطلب التلقائي التجار من الحظر أو التخفيض أو الحظر بسبب الأخطاء التي يرتكبها المستخدمون الذين يستولون على الطلبات. كوسيط ، سنواصل العمل الجاد لتحسين ثقة وكفاءة التجار والمستخدمين عند التنافس على الطلبات!',
    bronzeMember:'عضو برونزي',
    commission: 'عمولة',
    Matching:'مطابقة الطلبات لك ، يرجى الانتظار لحظة',
    dispatch:'أشخص',
    grabAnOrder:'انتزاع واحدة',
    balance:'الرصيد',
    recharge:'لاعادة الشحن',
    withdrawal:'التسهيلات',
    matchingRules:'قاعدة مطابقة',
    commissionEarnedToday:'تم كسب عمولة اليوم',
    Today:'اليوم تم سرقة المفرد',
    oddNumber:'المفرد المتبقية اليوم',
    yesterdays:'عمولة الأمس',
    User:' المستعمل ',
    getCommission:' الحصول على العمولة'
  },
  // my页面
  myPage:{
    logout:'خروج تسجيل الدخول',
    areYouSureYouWantToLogOut:'تأكد من الخروج من تسجيل الدخول?',
    confirnText:'تحديد',
    cancleText:'إلغاء',
    amazonReputation:'applovin مصداقية:',
    accountBalance:'رصيد الحساب',
    recharge:'لاعادة الشحن',
    withdrawal:'التسهيلات',
    deliveryAddress:'عنوان التسليم',
    iWantToPromote:'سوف أقوم بالترويج',
    secondPurchaseRecord:'ثانية ثانية',
    accountDetails:'حسابات مفصلة',
    teamReport:'تقرير الفريق',
    companyQualification:'شهادة التأسيس'
  },
  // 账户明细
  accountDetailsPage:{
    accountDetails:' حسابات مفصلة ',
    startDate:'تاريخ البدء',
    endDate:'تاريخ النهاية',
    search:'البحث',
    alltypes:'جميع الأنواع',
    withdrawalRecord:'سجل السحب',
    rechargeRecord:'سجلات الشحن',
    withDrawal:'السحب',
    UAddress:'عنوان U',
    rebateForOrderGrabbing:'خصم للاستيلاء على الطلبات',
    orderGrab:'انتزاع الطلبات',
    recharge:'اعاده شحن',
    tryToLoad:'تكافح من أجل التحميل',
    noMore:'كفاية',
    cancel:'إلغاء الأمر',
    ok:'هل أنت متأكد',
    pleaseSelectATime:'الرجاء اختيار الوقت'
},
// 团队
    teamPage:{
        teamReport:' تقرير الفريق ',
        startDate:'تاريخ البدء',
        endDate:'تاريخ النهاية',
        search:'البحث',
        teamBalance:'رصيد الفريق($)',
        teamFlow:'فريق ينفق($)',
        totalTeamRecharge:'الفريق يعمل دائماً($)',
        theTotalWithdrawalOfTheTeam:'مجموع السحب من الفريق($)',
        teamOrderCommission:'عمولة طلب الفريق($)',
        myCommission:'عمولتي($)',
        directPushNumber:'ادفع الأرقام',
        teamSize:'عدد الفريق',
        todaysNewNumberOfPeople: 'عدد إضافي اليوم',
        todaysActiveNumber:'عدد الناشطين اليوم',
        level1:'على الصعيد',
        level2:'المستوى الثاني',
        level3:'المستوى الثالث',
        tryToLoad:'تكافح من أجل التحميل',
        noMore:'كفاية',
        mobilePhone:'رقم الهاتف المحمول',
        Number:'عدد المروجين',
        withdrawals:'السحب',
        recharge:'اعاده شحن',
        time:'وقت التسجيل'
    },
    // 公司资质
    culturePage:{
      title:' مؤهلات الشركة ',
      text:'تلتزم أمازون بمفهوم "التركيز على العميل" وتلتزم بإيمان "الأسعار المنخفضة اليومية ، والمنتجات الأصلية" ، وتبيع عشرات الملايين من المنتجات مثل الكتب وأجهزة الكمبيوتر والأجهزة المنزلية الرقمية ومتاجر الأمهات والأطفال والملابس الحقائب وهلم جرا. تقدم أمازون الصين خدمات احترافية: المنتجات الأصلية المرخصة رخيصة كل يوم ، والفواتير المطبوعة بالآلة مضمونة على الصعيد الوطني. الدفع عند الاستلام ، الإرجاع خلال 30 يومًا. توفر أمازون للمستهلكين في جميع أنحاء العالم تجربة تسوق مريحة وسريعة عبر الإنترنت'
  },
  //   注册页
  registerPage:{
    register:'  تسجيل ',
    mobileNumber:'رقم الهاتف',
    mobileNumberPlaceholder :'يرجى إدخال رقم الهاتف المحمول',
    mobileNumberMessage:'الرجاء إدخال رقم الهاتف المحمول القياسي',
    loginPassword:'رمز الدخول',
    loginPasswordPlaceholder:'الرجاء إدخال كلمة مرور تسجيل الدخول الخاصة بك',
    loginPasswordMessage:'لا يمكن أن تكون كلمة مرور تسجيل الدخول التي قمت بتعيينها فارغة',
    invitationCode:'رمز الدعوة',
    invitationCodePlaceholder:'يرجى إدخال رمز الدعوة',
    invitationCodeMessage:'لقد أدخلت رمز الدعوة بشكل غير صحيح',
    fundingPassword:'رمز المال',
    fundingPasswordPlaceholder:'يرجى إدخال كلمة مرور الأموال',
    fundingPasswordMessage:'لا يمكن أن تكون كلمة مرور الصندوق التي قمت بتعيينها فارغة',
    iKnowAndAgree:'المعاهدات',
    accountOpeningAgreement:'"اتفاق فتح الحسابات"',
    theTreaties:'أنا أعلم وأوافق',
    signUpNow:'سجل على الفور',
    pleaseAgree:'يرجى الموافقة على اتفاقية التسجيل أولا',
    existingAccount:'لديك حساب بالفعل',
    loginNow:'"تسجيل الدخول الآن"',
    registrationFailed:'فشل التسجيل',
    registrationSuccessful:'تم التسجيل بنجاح',
    iKnow:'. فهمت',
    accountOpen:'اتفاق فتح الحسابات'
  },
  // 充值页面
  rechargePage:{
    recharge:' لاعادة الشحن ',
    currentBalance:'الرصيد الحالي ($)',
    rechargeApplication:'طلب الشحن($)',
    accumulatedRecharged:'تم الشحن التراكمي($)',
    rechargeAmount:'إعادة الشحن',
    pleaseEnterTheRechargeAmount:'الرجاء إدخال الكمية الشحن',
    rechargeNow:'الشحن الفوري'
  },
  // 抢单列表页面
  grabListPage:{
    failedToGetTheOrderGrabList:'فشل في الحصول على قائمة الاستيلاء على الطلب'
  },
  // 提现页面
  withDrawalPage:{
    withDrawal:' التسهيلات ',
    currentBalance:'الرصيد الحالي ($)',
    withdrawalApplicationInProgress:'طلب السحب($)',
    cumulativelyWithdrawn:'سُحبت مبالغ نقدية($)',
    withdrawalAmount:'المبالغ المسحوبة',
    pleaseEnterTheWithdrawalAmount:'الرجاء إدخال مبلغ السحب',
    withdrawAll:'نقداً',
    uAddress:'U العنوان',
    pleaseEnterUAddress:'يرجى إدخال U.',
    transactionPassword:'رمز التداول',
    pleaseEnterTheTransactionPassword:'يرجى إدخال كلمة مرور المعاملة',
    withdrawNow:'النقد الفوري',
    allFieldsCannotBeEmpty:'لا يمكن أن تكون جميع الحقول فارغة',
    able:'مبلغ نقدي قابل للسحب',
    networkType:'نوع الشبكة',
	minPrice: 'الحد الأدنى لمبلغ السحب 20 دولار'
},
// address页面
addressPage:{
  address:' العنوان ',
  realName:'الاسم الحقيقي',
  pleaseEnterYourRealName:'يرجى إدخال الاسم الحقيقي',
  mobileNumber:'رقم الهاتف',
  pleaseEnterThePhoneNumber:'يرجى إدخال رقم الهاتف المحمول',
  region:'المناطق',
  pleaseEnterTheRegion:'يرجى إدخال المنطقة',
  detailedAddress:'عنوان مفصل',
  pleaseEnterDetailedAddress:'يرجى إدخال العنوان المفصل',
  submit:'تقديم'
},
// Popularize页面
popularizePage:{
  iWantToPromote:' سوف أقوم بالترويج ',
  inviteNewUsers:'دعوة المستخدمين الجدد',
  winTheFutureWithYou:'يربح المستقبل معك',
  screenshot:'لقطة الشاشة تحافظ على رمز مزدوج',
  friend:'التعرف على الأصدقاء وتسجيلها',
  complete:'إكمال تسجيل سرقة',
  distribute:'توزيع الحزم النقدية في غضون 24 ساعة',
  invitationCode:'رمز الدعوة:'
},

vipPage: {
	'agent_mode': 'وضع الوكيل',
	'current_level': 'المستوى الحالي',
	'vip1_text1': 'احصل على مجموعة من 40 مهمة بيانات للتطبيق',
	'vip1_text2': 'ربح 0.50% لكل طلب',
	'vip1_text3': 'قم بالتفعيل باستخدام 100.00 USDT',
	
	'vip2_text1': 'احصل على مجموعة من 50 مهمة بيانات التطبيقات',
	'vip2_text2': 'ربح 0.60% لكل طلب',
	'vip2_text3': 'Activate with a 2,000.00 USDT',
	
	'vip3_text1': 'احصل على مجموعة من 55 مهمة بيانات للتطبيق',
	'vip3_text2': 'ربح 0.80% لكل طلب',
	'vip3_text3': 'قم بالتفعيل باستخدام 5,000.00 USDT',
	
	'vip4_text1': 'احصل على مجموعة من 60 مهمة بيانات التطبيقات',
	'vip4_text2': 'ربح 1.00% لكل تطبيق',
	'vip4_text3': 'قم بالتفعيل باستخدام 10,000.00 USDT',
}

}